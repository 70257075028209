<template>

  <b-row class="justify-content-center">
    <b-col
      md="6"
    >
      <b-card
        header-bg-variant="primary"
        header="Ajouter un type de certificat"
        header-text-variant="white"
      >

        <b-card-body>
          <validation-observer ref="certificatValidationRules">
            <b-form @submit.prevent="validationForm">
              <b-row class="mt-2">

                <b-col md="12">

                  <b-form-group
                    label="Nom du certificat"
                    label-for="label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="nom du certificat"
                      rules="required"
                    >
                      <b-form-input
                        id="label"
                        v-model="form.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nom du certificat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <label for="description">Description</label>
                    <b-form-textarea
                      id="description"
                      v-model="form.description"
                      placeholder="Description"
                      rows="2"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Signataire"
                    label-for="signer_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="signataire"
                      rules="required"
                    >
                      <v-select
                        id="signer_id"
                        v-model="form.signer_id"
                        label="name"
                        :options="usersSignataires"
                        :reduce="(usersSignataires) => usersSignataires.id"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Signataire"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Type de document"
                    label-for="document_type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="type de pièce"
                      rules="required"
                    >
                      <v-select
                        id="document_type"
                        v-model="form.document_types"
                        label="label"
                        multiple
                        :options="documentTypes"
                        :reduce="(documentTypes) => documentTypes.id"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Type de document"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <!-- submit and reset -->
                <b-col md="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class=" float-right"
                    :disabled="certificateTypeProcess"
                  >
                    Créer
                    <b-spinner
                      v-if="certificateTypeProcess"
                      small
                      label="Loading..."
                      variant="light"
                      class="ml-1"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="secondary"
                    class="mr-1 float-right"
                  >
                    Rafraichir
                  </b-button>
                </b-col>
              </b-row>
            </b-form>

          </validation-observer>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BFormGroup, BSpinner, BFormInput, BForm, BButton, BCardBody, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

import {
  onMounted, reactive,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/services/super-admin/userService'
// eslint-disable-next-line import/no-cycle
import useDocumentTypes from '@/services/admin/documentTypeService'
// eslint-disable-next-line import/no-cycle
import useCertificateTypes from '@/services/admin/certificateTypeService'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BFormTextarea,
    BCard,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  setup() {
    const { getUsersByRoleByMairieId, usersSignataires } = useUsers()
    const { storeOrUpdateCertificateType, certificateTypeProcess } = useCertificateTypes()
    const { listDocumentTypesByMairie, documentTypes } = useDocumentTypes()
    const form = reactive({
      name: '',
      signer_id: null,
      document_types: [],
      description: '',

    })
    onMounted(async () => {
      await getUsersByRoleByMairieId('SIGNATAIRE')
      await listDocumentTypesByMairie()

      // Set the initial number of items
    })
    const createCertificatType = async () => {
      await storeOrUpdateCertificateType({ ...form })
      console.clear()
    }

    return {
      form,
      createCertificatType,
      required,
      certificateTypeProcess,
      usersSignataires,
      documentTypes,
    }
  },
  methods: {
    validationForm() {
      this.$refs.certificatValidationRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.createCertificatType()
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
